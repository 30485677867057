.skills {
  .skillsContent {
    min-height: $minHeight;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: 100% 1fr 1fr;
    grid-template-rows: 70% 30%;
    grid-template-areas:
      "L"
      "E";

    .programmingFrameworks, .languages {
      background: $color-2;
      border-radius: $borderRadius;
      margin: 0 0 .5rem .5rem;
      padding: .8rem;
    }
    h3 {
      font-size: 1.4rem;
      margin-bottom: 1rem;
      font-family: $font-2;
      color: $color-5;
      //text-transform: uppercase;
    }

    .programmingFrameworks {
      grid-area: L;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 0.4fr;
      grid-template-areas:
        "L"
        "F";

      .backendDisplay {
        grid-area: L;
      }
      .otherDisplay {
        grid-area: F;
      }
      h3 {
        margin-bottom: 0.3rem;
      }
    }
    .years {
      font-size: .65rem;
      display: flex;
      justify-content: space-between;
      color: $white;

      span {
        width: 36%;

        &:nth-child(2){
          text-align: center;
          width: 20%;
        }
        &:nth-child(3){
          text-align: right;
          width: 44%;
        }
        &:nth-child(4){
          text-align: right;
          width: 64%;
        }

        &:nth-child(2):before,
        &:nth-child(3):before,
        &:nth-child(4):before {
          content:'';
          background: rgba(180, 179, 179, 0.507);
          position: absolute;
          height: 146px;
          width: 1px;
          transform: translate(9px, 19px);
        }
      }
    }
    .progressBarList {
      padding-top: .5rem;

      li {
        color: $color-1;
      }
      .progressBar {
        height: .4rem;
        background: transparent;
        border-radius: 50px;
        margin-top: 0.2rem;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          background: $color-3;
          border-radius: 50px;
          height: .4rem;
          width: 0;
          animation: line 2s ease-out forwards;

          @keyframes line {
            to {
              width: 100%;
            }
          }
        }
      }
    }
    .languages {
      grid-area: E;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 10% 1fr 1fr;
      grid-template-areas:
        "1"
        "2"
        "3";

      h3 {
        grid-area: 1;
      }
      h4 {
        font-size: 1.1rem;
        color: $color-5;
      }
      h5 {
        color: $color-3;
        padding: 0.5rem 0 0.5rem 1rem;
      }
      p {
        text-align: justify;
        padding: 0 1rem;
      }
      .exp-1 {
        grid-area: 2;
      }
      .exp-2 {
        grid-area: 3;
      }
    }
    .otherSkills {
      grid-area: O;
      margin-right: 0.5rem;

      .list {
        display: flex;
        width: 80%;
        height: 100%;
        margin: 0 auto;
        justify-content: space-between;

        ul {
          height: 80%;
          display: grid;

          i {
            color: $color-5;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 950px){
  .knowledgesContent {
    h3 {
      font-size: 1.2rem !important;
    }
    .progressBarList {
      padding-top: .2rem !important;
    }
    p {
      font-size: .8rem;
    }
    .otherSkills, .hobbies {
      font-size: .8rem;
    }
  }
}
@media screen and (max-width: 850px){
  .skillsContent {
    h4 {
      font-size: .9rem !important;
    }
    h5 {
      font-size: .6rem !important;
      padding: .2rem 1rem !important;
    }
  }
}
@media screen and (max-width: 500px){
  .skillsContent {
    display: grid !important;
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr 1fr 180px 180px !important;

    .programming {
      grid-area: 1 !important;
    }
    .languages {
      grid-area: 2 !important;
      margin-left: 0 !important;
    }
  }
}