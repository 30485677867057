.project {
  .projectContent {
    width: 100%;
    display: grid;
    min-height: $minHeight;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr;
    grid-template-areas:
      "1"
      "2";
  }

  .radioDisplay {
    grid-area: 1;
    border-radius: $borderRadius;
    display: flex;
    padding: 1rem 2rem;
    background: $color-1;
    box-shadow: $shadow;
    font-size: 1.3rem;
    color: $color-5;
    justify-content: center;
  }

  .projects {
    grid-area: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .projectItem {
      background: $color-2;
      border-radius: $borderRadius;
      margin: 0.5rem 0 0 0;
      text-align: center;
      width: 32%;
      max-height: 50%;
      padding: .3rem;
      box-shadow: $shadow;
      display: grid;
      grid-template-rows: 15% 25% 40% 20%;
      grid-template-areas:
        "1"
        "2"
        "3"
        "4";
    }
    .icons {
      grid-area: 1;
      display: flex;
      justify-content: center;
      @include verticalCenter;

      i {
        font-size: 1.4rem;
        padding: 0 .35rem;
        color: $color-5;
        text-shadow: 0 0px 1px $color-1;
      }
    }
    h3 {
      grid-area: 2;
      font-family: $font-2;
      font-size: 1.65rem;
      @include verticalCenter;
      justify-content: center;
    }
    .text {
      grid-area: 3;
       text-align: justify;
       padding-bottom: 1rem;
    }
    .button {
      grid-area: 4;
      cursor: pointer;
      color: $color-1;
      background: $color-5;
      border-radius: $borderRadius;
      padding: 1rem;
      transition: .2s;

      &:hover {
        background: $color-4;
        transform: scale(1.1);
      }
    }
  }
}
@media (max-width: 650px) and (min-width: 521px){
  .projectContent {
    .projectItem {
      width: 49% !important;

      i {
        font-size: 1rem;
      }
    }
    .infosContent {
      width: 100% !important;
    }
    .text {
      font-size: 0.95rem !important;
    }
  }
}
@media (max-width: 520px){
  .radioDisplay {
    display: none !important;
  }
  .projectContent {
    grid-template-rows: 1fr !important;

    .text {
      font-size: 0.95rem !important;
    }
    .projectItem {
      grid-template-rows: 30px 40px 80px 40px !important;
      width: 98% !important;

      img {
        height: 100% !important;
      }
    }
  }
}